<template>
	<div class="layindex">
		<div class="mcover" v-show="shareFlag" @click="closeShare"><img src="@/assets/share_tips.png"></div>
		<v-telephone></v-telephone>
		<img class="logoname" src="@/assets/logoname.png" alt="">
		<div class="addressbox">
			<img class="icon" src="@/assets/icon-address.png" alt="">
			<div class="address" @click="goLocation">{{ systemInfo.address }}</div>
		</div>
		<div class="bannerbox" v-if="bannerlist.length > 0">
			<cube-slide ref="slide" :interval="4000" :data="bannerlist" @change="changePage">
				<cube-slide-item v-for="(item, index) in bannerlist" :key="index" @click.native="clickHandler(item, index)">
					<img class="pic" :src="item.image">
				</cube-slide-item>
				<template slot="dots" slot-scope="props">
					<span class="my-dot" :class="{ active: props.current === index }" v-for="(item, index) in props.dots"
						:key="index"></span>
				</template>
			</cube-slide>
		</div>
		<div class="menubox">
			<div class="item" @click="getQrcode('1')" v-if="isSupper == 0">
				<img style="width: 52px; height: 54px;" class="icon" src="@/assets/signin.png" alt="">
				<div class="name">入场</div>
			</div>
			<div class="item" @click="getQrcode('2')" v-if="isSupper == 0">
				<img style="width: 56px; height: 53px;" class="icon" src="@/assets/signout.png" alt="">
				<div class="name">出场</div>
			</div>
			<div class="item" @click="reserveCharter" v-if="isSupper == 0">
				<img style="width: 58px; height: 51px;" class="icon" src="@/assets/charter.png" alt="">
				<div class="name">包场</div>
			</div>
			<div class="item" @click="jumpTo('/integral-shop')">
				<img style="width: 58px; height: 51px;" class="icon" src="@/assets/integral-shop.png" alt="">
				<div class="name">积分商城</div>
			</div>
			<div class="item" @click="shareActivity">
				<img style="width: 58px; height: 51px;" class="icon" src="@/assets/activity.png" alt="">
				<div class="name">分享活动</div>
			</div>
			<div class="item" @click="getSqrcode" v-if="isSupper == 1">
				<img style="width: 52px; height: 54px;" class="icon" src="@/assets/signin.png" alt="">
				<div class="name">门禁卡</div>
			</div>
		</div>
		<cube-extend-popup :qrdata="qrdata" @haha="updatecode" ref="extendPopup"></cube-extend-popup>
	</div>
</template>

<script>
import cubeExtendPopup from '@/components/cube-extend-popup.vue'
import telephone from '@/components/telephone.vue'
import config from '@/utils/config.js'
export default {
	name: 'index',
	components: {
		'cube-extend-popup': cubeExtendPopup,
		'v-telephone': telephone,
	},
	created () {
		this.getBanner()
		this.getSystemInfo()
		this.isSupper = localStorage.getItem('isSupper') || 0
	},
	mounted () {
		console.log('mounted-index', this.$store.state.userInfo)
		if (this.$store.state.userInfo.id > 0) {
			this.$utils.getWxJsConfig()
		}
	},
	data () {
		return {
			shareFlag: false,
			isSupper: 0, //是否为超管角色
			bannerlist: [],
			qrdata: {}, //require('../assets/tabball.png')
			systemInfo: '',
		}
	},
	methods: {
		goLocation () {
			this.$wx.openLocation({
				latitude: this.systemInfo.location_info.latitude,//目的地latitude
				longitude: this.systemInfo.location_info.longitude,//目的地longitude
				name: this.systemInfo.location_info.name,
				address: this.systemInfo.location_info.address,
				scale: 15//地图缩放大小，可根据情况具体调整
			});
		},
		reserveCharter () {
			let that = this;
			this.$createDialog({
				type: 'confirm',
				title: '提示',
				content: that.systemInfo.charter_desc,
				//icon: 'cubeic-alert',
				cancelBtn: {
					text: '取消',
					active: false,
					disabled: false,
					href: 'javascript:;'
				},
				confirmBtn: {
					text: '确定',
					active: true,
					disabled: false,
					href: 'javascript:;'
				},
				onConfirm: () => {
					that.$http.reserveCharter().then(res => {
						if (res.code == 200) {
							that.$createDialog({
								type: 'alert',
								title: '提示',
								content: res.message,
							}).show()
						}
					}).catch(err => {
						console.log(err);
					});
				},
			}).show()
		},
		jumpTo (path) {
			if (path == "/activity") {
				this.$createToast({
					txt: "暂未开放，敬请期待",
					type: 'txt'
				}).show()
			} else {
				this.$router.push({ path: path })
			}
		},
		getSqrcode () {
			this.$http.getSqrcode().then(res => {
				if (res.code == 200) {
					this.qrdata = res.data;
					this.showPopup('extendPopup')
				}
			}).catch(err => {
				console.log(err);
			});
		},
		getBanner () {
			this.$http.getBanner().then(res => {
				if (res.code == 200) {
					this.bannerlist = res.data;
				}
			}).catch(err => {
				console.log(err);
			});
		},
		getSystemInfo () {
			this.$http.getSystemInfo().then(res => {
				if (res.code == 200) {
					this.systemInfo = res.data;
					window.localStorage.setItem('systemTelephone', res.data.phone)
				}
			}).catch(err => {
				console.log(err);
			});
		},
		showPopup (refId) {
			const component = this.$refs[refId]
			component.show()
		},
		updatecode (data) {
			console.log(data)
		},
		changePage (current) {
			// console.log('当前轮播图序号为:' + current)
		},
		clickHandler (item, index) {
			console.log(item, index)
			let articleId = item.article_id
			// this.$router.push({path: `/explain/${articleId}`});
			this.$router.push({
				path: '/explain',
				query: {
					articleId: articleId
				}
			});
		},
		getQrcode (type) {
			this.$http.getQrcode({
				'type': type
			}).then(res => {
				if (res.code == 200) {
					this.qrdata = res.data;
					this.showPopup('extendPopup')
				}
			}).catch(err => {
				console.log(err);
			});
		},
		shareActivity () {
			this.$createDialog({
				type: 'confirm',
				title: '提示',
				content: "老会员分享邀请新客注册，新客首次充值会员后，老会员会员账户自动获得" + (Object.keys(this.systemInfo).length > 0 && this.systemInfo.share_reward.length > 0 ? this.systemInfo.share_reward : 30) + "元会费，无推荐上限！",
				cancelBtn: {
					text: '取消',
					active: false,
					disabled: false,
					href: 'javascript:;'
				},
				confirmBtn: {
					text: '分享',
					active: true,
					disabled: false,
					href: 'javascript:;'
				},
				onConfirm: () => {
					this.shareFlag = true;
				},
			}).show()
		},
		closeShare () {
			this.shareFlag = false;
		}
	}
}
</script>

<style scoped lang="less">
.layindex {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 9;
	overflow-y: auto;


	.mcover {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		z-index: 20000;

		img {
			position: fixed;
			right: 18px;
			top: 5px;
			width: 260px;
			height: 180px;
			z-index: 20001;
		}
	}

	.telbox {
		position: absolute;
		box-sizing: border-box;
		width: 57px;
		padding: 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		right: 10px;
		top: 10px;
		z-index: 10;
		background-color: #F9A709;
		border-radius: 20px;

		.icontel {
			width: 14px;
			height: 15px;
		}

		.teltext {
			font-size: 12px;
			color: #FFFFFF;
		}
	}

	.logoname {
		width: 158px;
		height: 35px;
		margin: 34px auto 0;
		display: block;
	}

	.addressbox {
		width: 100%;
		box-sizing: border-box;
		padding: 11px 23px 23px 23px;
		display: flex;
		justify-content: center;

		.icon {
			width: 12px;
			height: 16px;
			margin-right: 5px;
		}

		.address {
			font-size: 12px;
			color: #FFFFFF;
			line-height: 18px;
			letter-spacing: 1px;
		}
	}

	.menubox {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: center;
		// margin-top: 20px;

		.item {
			width: 110px;
			height: 118px;
			opacity: 0.9;
			border-radius: 15px;
			background-color: #FFFFFF;
			text-align: center;
			overflow: hidden;
			// margin-bottom: 20px;
			transform: scale(0.8);

			.icon {
				margin: 18px 0 12px;
			}

			.name {
				font-size: 15px;
				color: #2A2A2A;
			}
		}
	}

	.bannerbox {
		width: 100%;
		box-sizing: border-box;
		padding: 0 10px;
		height: 215px;
		overflow: hidden;

		.cube-slide {
			box-sizing: border-box;
			padding-bottom: 25px;
		}

		.cube-slide-dots .my-dot {
			width: 7.5px;
			height: 7.5px;
			border-radius: 50%;
			margin: 0 4px;
		}

		.cube-slide-dots .my-dot.active {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #F9A709;
		}

		.cube-slide-item {
			border-radius: 15px;
			overflow: hidden;
		}

		.pic {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
