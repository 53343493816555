<template>
  <cube-popup type="extend-popup" ref="popup" :mask-closable="true">
    <div class="cube-extend-popup-content" @click="qrcodefun">
		<img class="codepic" :src="qrdata.qrcode_url"/>
		<p class="des" v-if="qrdata.msg">{{qrdata.msg}}</p>
    </div>
 </cube-popup>
</template>

<script>
export default {
  name: 'cube-extend-popup',
  props: {
		qrcode: {
			type: String
		},
		qrdata: {
			type: Object
		},
	},
	methods: {
		show() {
			this.$refs.popup.show()
		},
		hide() {
			this.$refs.popup.hide()
			//this.$emit('hide')
		},
		qrcodefun(){
			this.$emit('haha',this.qrdata)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.cube-extend-popup{
	.cube-extend-popup-content{
		background-color: #ffffff;
		padding: 41px 0;
		width: 294px;
		border-radius:15px;
		text-align: center;
		.codepic{width: 132px; height: 132px;}
		.des{font-size: 16px; color:#2A2A2A; margin-top: 25px;}
	}
}
</style>
<style>
	.cube-popup-mask{opacity: 0.7;}
</style>
